<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" fill="none">
        <path fill="currentColor"
            d="M21.416 5.146c.61.61.61 1.602 0 2.212l-12.5 12.5c-.61.61-1.602.61-2.212 0l-6.25-6.25a1.565 1.565 0 0 1 2.212-2.212l5.146 5.142L19.21 5.146c.61-.61 1.602-.61 2.212 0h-.005Z"
        />
    </svg>
)
</script>

<style lang="scss" scoped>

</style>
